import { Ad } from "components/Ads";
import { Fragment, ReactElement } from "react";
import { useWindowSize } from "utils/hooks";

type ListSideAdsProps = {
  rows: number;
};

export default function ListSideAds({
  rows,
}: ListSideAdsProps): ReactElement | null {
  const { width } = useWindowSize();

  return width && width >= 1024 ? (
    <Fragment>
      {rows > 0 && (
        <div className="flex flex-col flex-grow pt-8 pr-6 first:pt-0">
          <Ad
            id="1631037772625-0"
            slot="Listy_lewa1"
            className="lg:sticky top-36 xl:top-24"
          />
        </div>
      )}

      {rows > 2 && (
        <div className="flex flex-col flex-grow pt-8 pr-6">
          <Ad
            id="1631037797490-0"
            slot="Listy_lewa2"
            className=" lg:sticky top-36 xl:top-24"
          />
        </div>
      )}

      {rows > 5 && (
        <div className="flex flex-col flex-grow pt-8 pr-6">
          <Ad
            id="1631037821839-0"
            slot="Listy_lewa3"
            className="lg:sticky top-36 xl:top-24"
          />
        </div>
      )}
    </Fragment>
  ) : null;
}
