import { Ad } from "components/Ads";
import { Fragment, ReactElement } from "react";
import { useWindowSize } from "utils/hooks";

type ArticleSideAdsProps = {
  readTime: number;
};

export default function ArticleSideAds({
  readTime,
}: ArticleSideAdsProps): ReactElement | null {
  const { width } = useWindowSize();

  return width && width >= 1024 ? (
    <Fragment>
      {readTime > 0 && (
        <div className="flex flex-col flex-grow pt-8">
          <Ad
            id="1631037245701-0"
            slot="Art_lewa1"
            className="lg:sticky top-36 xl:top-24"
          />
        </div>
      )}

      {readTime > 1 && (
        <div className="flex flex-col flex-grow pt-8">
          <Ad
            id="1631037279415-0"
            slot="Art_lewa2"
            className=" lg:sticky top-36 xl:top-24"
          />
        </div>
      )}

      {readTime > 2 && (
        <div className="flex flex-col flex-grow pt-8">
          <Ad
            id="1631037306768-0"
            slot="Art_lewa3"
            className=" lg:sticky top-36 xl:top-24"
          />
        </div>
      )}
    </Fragment>
  ) : null;
}
