import { Ad } from "components/Ads";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { useWindowSize } from "utils/hooks";

export default function ArticleBottomAds(): ReactElement | null {
  const { width } = useWindowSize();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setTimeout(() => setShouldRender(true), 1500);
  }, []);

  return (
    <Fragment>
      {width && shouldRender && (
        <Ad id="1631037220723-0" slot="Art_dol" className="my-8 lg:my-12" />
      )}
    </Fragment>
  );
}
