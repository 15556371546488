import { HTMLProps, ReactElement, ReactHTML } from "react";
import { composeClassName } from "utils/misc";

type ContainerSectionProps = {
  transparent?: boolean;
  $as?: keyof ReactHTML;
} & HTMLProps<HTMLDivElement>;

export default function ContainerSection({
  transparent,
  className,
  children,
  $as = "section",
}: ContainerSectionProps): ReactElement {
  return (
    <$as
      className={composeClassName(
        "px-3 mx-auto max-w-screen-2xl 2xl:px-0",
        !transparent
          ? "dark:bg-black-200 bg-white before:-top-8 before:-bottom-8 before:left-0 before:right-0 2xl:before:-inset-8 relative first-of-type:before:rounded-t-xl last-of-type:before:-bottom-12 before:absolute dark:before:bg-black-200 before:bg-white children:z-10 children:relative"
          : "pointer-events-none",
        "empty:hidden",
        className
      )}
    >
      {children}
    </$as>
  );
}
