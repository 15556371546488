import { useEffect } from "react";
import { snakeCase } from "utils/misc";

import {
  Article,
  ArticleCategory,
  Game,
  Magazine,
  Video,
} from "./../utils/api/models";

type Props = {
  type:
    | "Glowna"
    | "Kategorie"
    | "Podkategorie"
    | "Artykuly"
    | "Wideo"
    | "Czasopisma"
    | "Gry";
  category?: ArticleCategory;
  subcategory?: ArticleCategory;
  article?: Article;
  game?: Game;
  video?: Video;
  magazine?: Magazine;
  isTransitioning: boolean;
};

export function useAdTargeting({
  type,
  category,
  subcategory,
  article,
  game,
  video,
  magazine,
  isTransitioning,
}: Props): void {
  useEffect(() => {
    if (!isTransitioning && typeof window !== undefined) {
      let comment: Comment;

      googletag.cmd.push(function () {
        googletag
          .pubads()
          .setTargeting(
            "wersja_dev",
            window.location.hostname.includes("cdaction.pl") ? "NIE" : "TAK"
          );

        googletag.pubads().setTargeting("podstrony", type);

        googletag
          .pubads()
          .setTargeting(
            "kategoria",
            snakeCase(category?.name) ||
              snakeCase(article?.category?.parent?.name) ||
              snakeCase(article?.category?.name) ||
              ""
          );

        googletag
          .pubads()
          .setTargeting(
            "podkategoria",
            snakeCase(subcategory?.name) ||
              (article?.category?.parent?.name &&
                snakeCase(article?.category?.name)) ||
              ""
          );

        (article || game || video || magazine) &&
          googletag
            .pubads()
            .setTargeting(
              "ID_obiektu",
              article?.id.toString() ||
                game?.id.toString() ||
                video?.id.toString() ||
                magazine?.id.toString() ||
                ""
            );

        (article || game || video) &&
          googletag
            .pubads()
            .setTargeting(
              "tagi",
              article?.tags.map((tag) => tag.name).join(",") ||
                game?.tags.map((tag) => tag.name).join(",") ||
                video?.tags.map((tag) => tag.name).join(",") ||
                ""
            );

        article &&
          googletag
            .pubads()
            .setTargeting("art_spons", article?.isSponsored ? "TAK" : "NIE");

        comment = document.createComment(
          `\n\tGOOGLE AD TARGETING INFO:\n${googletag
            .pubads()
            .getTargetingKeys()
            .map(
              (key) =>
                `\t\t${key}: ${googletag.pubads().getTargeting(key).join("")}`
            )
            .join("\n")}\n`
        );

        window.document.prepend(comment);
      });

      return () => comment?.remove();
    }
  }, [type, category, subcategory, article, isTransitioning]);
}
