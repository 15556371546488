import { useTransition } from "contexts/transition-context";
import { useAdSlot } from "hooks/use-ad-slot";
import type { ReactElement } from "react";
import { ADS } from "utils/constants";
import { composeClassName } from "utils/misc";

type AdProps = {
  id?: string;
  slot?: string;
  className?: string;
  outOfPage?: boolean;
};

export default function Ad({
  id,
  slot,
  className,
  outOfPage,
}: AdProps): ReactElement | null {
  if (!id || !slot) return null;

  const { isTransitioning } = useTransition();
  const ad = ADS[id];

  useAdSlot({
    sizes: outOfPage ? [] : ad?.sizes,
    adId: id,
    id: slot,
    outOfPage,
    isTransitioning,
  });

  return (
    <div
      className={composeClassName(
        !outOfPage && "flex flex-col justify-end items-center z-20",
        !outOfPage &&
          !slot?.includes("sitebranding") &&
          "before:content-['Reklama'] before:mix-blend-lighten before:mb-5 before:font-mono before:text-xs before:font-bold before:text-center before:text-gray-500 before:uppercase",
        !outOfPage &&
          !slot?.includes("sitebranding") &&
          "!-ml-3 !-mr-3 lg:!ml-0 lg:!mr-0",
        "pointer-events-none children:pointer-events-auto",
        "empty:hidden",
        className
      )}
      id={`div-gpt-ad-${id}`}
    >
      {!outOfPage && !slot?.includes("sitebranding") && (
        <div className="flex items-center justify-center w-full mx-auto font-mono text-xs font-bold text-gray-500 uppercase bg-gray-100 rounded-lg h-52 max-w-screen-2xl dark:bg-black-300" />
      )}
    </div>
  );
}
