import { useEffect } from "react";

type Props = {
  sizes: string[][];
  adId: string;
  id: string;
  outOfPage?: boolean;
  isTransitioning: boolean;
};

export function useAdSlot({
  sizes,
  adId,
  id,
  outOfPage,
  isTransitioning,
}: Props): void {
  useEffect(() => {
    if (!isTransitioning && typeof window !== undefined) {
      googletag.cmd.push(function () {
        const possibleDuplicate = !!(
          Object.keys(googletag.pubads().getSlotIdMap()) as Array<string>
        ).find((key) => key.includes(id));

        if (possibleDuplicate) return false;

        let mapping = googletag.sizeMapping().build();

        if (
          [
            "SG_sitebranding",
            "Art_sitebranding",
            "Listy_sitebranding",
          ].includes(id)
        ) {
          mapping = googletag
            .sizeMapping()
            .addSize([1920, 0], ["fluid", [1920, 730], [1280, 420]])
            .addSize([1280, 0], ["fluid", [1280, 420]])
            .addSize([0, 0], ["fluid", [1, 1]])
            .build();
        }

        if (["SG_gora", "Art_gora", "Listy_gora"].includes(id)) {
          mapping = googletag
            .sizeMapping()
            .addSize(
              [1220, 0],
              [
                "fluid",
                [1220, 300],
                [985, 400],
                [970, 300],
                [970, 250],
                [970, 200],
                [970, 100],
                [970, 90],
                [950, 300],
                [950, 200],
                [950, 100],
                [750, 300],
                [750, 200],
                [750, 100],
              ]
            )
            .addSize(
              [985, 0],
              [
                "fluid",
                [985, 400],
                [970, 300],
                [970, 250],
                [970, 200],
                [970, 100],
                [970, 90],
                [950, 300],
                [950, 200],
                [950, 100],
                [750, 300],
                [750, 200],
                [750, 100],
              ]
            )
            .addSize(
              [750, 0],
              ["fluid", [750, 300], [750, 200], [750, 100], [728, 90]]
            )
            .addSize(
              [0, 0],
              ["fluid", [336, 280], [320, 100], [320, 50], [300, 250], [1, 1]]
            )
            .build();
        }

        if (
          [
            "SG_srodek1",
            "SG_srodek2",
            "SG_srodek3",
            "SG_dol",
            "Art_srodek1",
            "Art_dol",
            "Listy_dol",
          ].includes(id)
        ) {
          mapping = googletag
            .sizeMapping()
            .addSize(
              [1220, 0],
              [
                "fluid",
                [1220, 300],
                [985, 400],
                [970, 300],
                [970, 250],
                [970, 200],
                [970, 100],
                [970, 90],
                [950, 300],
                [950, 200],
                [950, 100],
                [750, 300],
                [750, 200],
                [750, 100],
              ]
            )
            .addSize(
              [985, 0],
              [
                "fluid",
                [985, 400],
                [970, 300],
                [970, 250],
                [970, 200],
                [970, 100],
                [970, 90],
                [950, 300],
                [950, 200],
                [950, 100],
                [750, 300],
                [750, 200],
                [750, 100],
              ]
            )
            .addSize(
              [750, 0],
              ["fluid", [750, 300], [750, 200], [750, 100], [728, 90]]
            )
            .addSize(
              [0, 0],
              [
                "fluid",
                [336, 280],
                [320, 100],
                [320, 50],
                [300, 600],
                [300, 400],
                [300, 250],
                [1, 1],
              ]
            )
            .build();
        }

        if (["SG_gora2"].includes(id)) {
          mapping = googletag
            .sizeMapping()
            .addSize([1280, 0], ["fluid", [750, 100], [728, 90]])
            .addSize([1024, 0], ["fluid", [468, 60]])
            .addSize(
              [750, 0],
              ["fluid", [750, 300], [750, 200], [750, 100], [728, 90]]
            )
            .addSize([728, 0], ["fluid", [728, 90]])
            .addSize(
              [0, 0],
              [
                "fluid",
                [336, 280],
                [320, 100],
                [320, 50],
                [300, 250],
                [300, 400],
                [300, 600],
                [1, 1],
              ]
            )
            .build();
        }

        if (
          [
            "SG_lewa1",
            "SG_lewa2",
            "SG_lewa3",
            "SG_Sekcja",
            "Art_lewa1",
            "Art_lewa2",
            "Art_lewa3",
            "Art_lewa4",
            "Art_lewa5",
            "Listy_lewa1",
            "Listy_lewa2",
            "Listy_lewa3",
          ].includes(id)
        ) {
          mapping = googletag
            .sizeMapping()
            .addSize([1220, 0], ["fluid", [300, 600], [300, 400], [300, 250]])
            .addSize([990, 0], ["fluid"])
            .addSize([750, 0], ["fluid", [300, 600], [300, 400], [300, 250]])
            .addSize(
              [0, 0],
              [
                "fluid",
                [300, 600],
                [300, 400],
                [300, 250],
                [336, 280],
                [320, 100],
                [320, 50],
                [1, 1],
              ]
            )
            .build();
        }

        if (["Art_srodtekst1", "Art_srodtekst2"].includes(id)) {
          mapping = googletag
            .sizeMapping()
            .addSize(
              [1440, 0],
              ["fluid", [750, 300], [750, 200], [750, 100], [728, 90]]
            )
            .addSize([1024, 0], ["fluid"])
            .addSize(
              [750, 0],
              ["fluid", [750, 300], [750, 200], [750, 100], [728, 90]]
            )
            .addSize(
              [0, 0],
              [
                "fluid",
                [336, 280],
                [320, 100],
                [320, 50],
                [300, 250],
                [300, 600],
                [1, 1],
              ]
            )
            .build();
        }

        if (["Listy_srodek1", "Listy_srodek2"].includes(id)) {
          mapping = googletag
            .sizeMapping()
            .addSize(
              [1440, 0],
              [
                "fluid",
                [985, 400],
                [970, 300],
                [970, 250],
                [970, 200],
                [970, 100],
                [970, 90],
                [950, 300],
                [950, 200],
                [950, 100],
                [750, 300],
                [750, 200],
                [750, 100],
                [728, 90],
              ]
            )
            .addSize(
              [1340, 0],
              [
                "fluid",
                [950, 300],
                [950, 200],
                [950, 100],
                [750, 300],
                [750, 200],
                [750, 100],
                [728, 90],
              ]
            )
            .addSize([1024, 0], ["fluid", [728, 90]])
            .addSize(
              [985, 0],
              [
                "fluid",
                [985, 400],
                [970, 300],
                [970, 250],
                [970, 200],
                [970, 100],
                [970, 90],
                [950, 300],
                [950, 200],
                [950, 100],
                [750, 300],
                [750, 200],
                [750, 100],
                [728, 90],
              ]
            )
            .addSize([728, 0], ["fluid", [728, 90]])
            .addSize(
              [0, 0],
              [
                "fluid",
                [336, 280],
                [320, 100],
                [320, 50],
                [300, 600],
                [300, 400],
                [300, 250],
                [1, 1],
              ]
            )
            .build();
        }

        if (outOfPage) {
          googletag
            .defineOutOfPageSlot(
              `/22087079936/Cdaction2/${id}`,
              `div-gpt-ad-${adId}`
            )
            ?.addService(googletag.pubads());
        } else {
          googletag
            .defineSlot(
              `/22087079936/Cdaction2/${id}`,
              sizes as googletag.GeneralSize,
              `div-gpt-ad-${adId}`
            )
            ?.defineSizeMapping(mapping)
            .addService(googletag.pubads());
        }

        googletag.enableServices();
      });

      googletag.cmd.push(function () {
        googletag.display(`div-gpt-ad-${adId}`);
      });
    }
  }, [sizes, adId, id, isTransitioning]);
}
