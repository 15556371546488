import type { ReactElement } from "react";
import { composeClassName } from "utils/misc";

type HrProps = {
  className?: string;
  top?: boolean;
};

export default function Hr({ className, top }: HrProps): ReactElement {
  return (
    <hr
      className={composeClassName(
        "w-full h-px my-8 bg-gray-100 border-none outline-none dark:bg-black-100 overflow-visible mx-auto max-w-screen-2xl",
        top &&
          "before:-top-8 before:-bottom-8 before:left-0 before:right-0 2xl:before:-inset-8 relative last-of-type:before:rounded-b-xl first-of-type:before:rounded-t-xl before:absolute dark:before:bg-black-200 before:bg-white children:z-10 children:relative",
        top &&
          "after:left-0 after:right-0 2xl:after:-left-8 2xl:after:-right-8 after:h-px relative last-of-type:after:rounded-b-xl first-of-type:after:rounded-t-xl after:absolute dark:after:bg-black-100 after:bg-gray-100 after:z-10",
        className
      )}
    />
  );
}
