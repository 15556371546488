import { Ad } from "components/Ads";
import ContainerSection from "components/ContainerSection";
import Hr from "components/Hr";
import Layout from "components/Layout";
import { useTransition } from "contexts/transition-context";
import { useAdTargeting } from "hooks/use-ad-targeting";
import { GetStaticPropsContext, GetStaticPropsResult, NextPage } from "next";
import Head from "next/head";
import React, { ReactElement } from "react";
import { Fragment } from "react";
import { QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";
import {
  Custom,
  Featured,
  Magazine,
  NaLuzie,
  News,
  Reviews,
  Tech,
  Texts,
  Videos,
} from "sections/Home";
import { fetchArticles, fetchSections, fetchVideos } from "utils/api";
import { useSectionsQuery } from "utils/api/hooks";
import { articlesKeys, sectionsKeys, videosKeys } from "utils/api/queryKeys";
import { BasePageProps } from "utils/types";

export async function getStaticProps({
  preview,
}: GetStaticPropsContext): Promise<GetStaticPropsResult<BasePageProps>> {
  if (preview) {
    return {
      redirect: {
        permanent: false,
        destination: "/api/exit-preview",
      },
    };
  }

  const queryClient = new QueryClient();

  // Featured prefetch
  const featuredParams = {
    isRecommended: true,
    sort: "-isFeatured,-isPinned,-publishedAt",
  };

  await queryClient.prefetchQuery(articlesKeys.filtered(featuredParams), () =>
    fetchArticles(featuredParams)
  );

  // News prefetch
  const newsParams = {
    categoryId: 1,
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  };

  await queryClient.prefetchQuery(articlesKeys.filtered(newsParams), () =>
    fetchArticles(newsParams)
  );

  // Reviews prefetch
  const reviewsParams = {
    categoryId: 2,
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  };

  await queryClient.prefetchQuery(articlesKeys.filtered(reviewsParams), () =>
    fetchArticles(reviewsParams)
  );

  // Texts prefetch
  const textsParams = {
    categoryId: 4,
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  };

  await queryClient.prefetchQuery(articlesKeys.filtered(textsParams), () =>
    fetchArticles(textsParams)
  );

  // Magazine prefetch
  const magazineParams = {
    categoryId: 8,
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  };

  await queryClient.prefetchQuery(articlesKeys.filtered(magazineParams), () =>
    fetchArticles(magazineParams)
  );

  // Videos prefetch
  const videosParams = {
    isFeaturedOnHomePage: true,
    isBlockedFromHomePage: false,
    sort: "-publishedAt",
  };

  await queryClient.prefetchQuery(videosKeys.filtered(videosParams), () =>
    fetchVideos(videosParams)
  );

  // Tech prefetch
  const techParams = {
    categoryId: "in:9,10,11",
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  };

  await queryClient.prefetchQuery(articlesKeys.filtered(techParams), () =>
    fetchArticles(techParams)
  );

  // Na luzie prefetch
  const naluzieParams = {
    categoryId: "in:14,15,16,17",
    sort: "-isFeatured,-isPinned,-publishedAt",
    isRecommended: false,
  };

  await queryClient.prefetchQuery(articlesKeys.filtered(naluzieParams), () =>
    fetchArticles(naluzieParams)
  );

  // Sections prefetch
  await queryClient.prefetchQuery(sectionsKeys.all, fetchSections);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 200,
  };
}

export default function HomePage(): ReactElement {
  const customSectionsQuery = useSectionsQuery();

  const { isTransitioning } = useTransition();

  useAdTargeting({
    type: "Glowna",
    isTransitioning,
  });

  return (
    <Fragment>
      <Head>
        <title>CD-Action – Strona główna</title>

        <meta
          name="description"
          content="CD-Action – strona największego w Polsce pisma dla graczy. Pełne wersje gier, recenzje, forum, sprzęt, wymagania sprzętowe. Gry komputerowe (PC) oraz na konsole (PS3, PS4, XBOX 360, XBOX ONE)."
        />

        <meta
          name="keywords"
          content="CD-Action, CDA, gry komputerowe, samochodowe, strategiczne, przygodowe, PS3, Xbox 360, PC game,newsy, recenzje, zapowiedzi, konkursy, forum, opinie, galerie screenów, trailery, filmiki, patche, teksty"
        />

        <meta name="og:image" content="/og-image.png" />
      </Head>

      <Ad id="1631036037188-0" slot="SG_outofpage" outOfPage />

      <ContainerSection $as="div" transparent>
        <Ad id="1631036214271-0" slot="SG_gora" className="my-8 lg:my-12" />
      </ContainerSection>

      <Featured />

      <ContainerSection>
        <Ad id="1631036367491-0" slot="SG_srodek1" className="my-8 lg:my-12" />
      </ContainerSection>

      <Hr top />

      <News />

      <Hr top />

      {customSectionsQuery.data?.results?.map((section) => (
        <Fragment key={section.name}>
          <Custom section={section} />

          <Hr top />
        </Fragment>
      ))}

      <Reviews />

      <ContainerSection>
        <Ad id="1631036433348-0" slot="SG_srodek2" className="my-8 lg:my-12" />
      </ContainerSection>

      <Hr top />

      <Texts />

      <Hr top />

      <Magazine />

      <Videos />

      <ContainerSection>
        <Ad id="1631036465772-0" slot="SG_srodek3" className="my-8 lg:my-12" />
      </ContainerSection>

      <Tech />

      {/* <Hr top />

      <ActionMag />

      <Hr top />

      <Deals /> */}

      <Hr top />

      <NaLuzie />

      <ContainerSection>
        <Ad id="1631036529891-0" slot="SG_dol" className="my-8 lg:my-12" />
      </ContainerSection>
    </Fragment>
  );
}

// eslint-disable-next-line react/display-name
HomePage.getLayout = (_pageProps: BasePageProps, page: NextPage) => {
  return (
    <Layout topAds={<Ad id="1631035881684-0" slot="SG_sitebranding" />}>
      {page}
    </Layout>
  );
};
